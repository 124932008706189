import React, { useRef, useEffect, useState } from "react"
import * as styles from './style.module.sass'
import { useLocation } from "@reach/router"

import MenuButton from "../../atoms/MenuButton"
import LogoItem from "../../atoms/LogoItem"
import Navigation from "../../molecules/Navigation"

const Header = (props) => {
  const duration = useRef(0);
  const location = useLocation();
  const [isOpenNav, setIsOpenNav] = useState(false);

  const timeout = useRef(null);
  const headerDom = useRef(null);

  const variants = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: {
        duration: duration.current,
        delay: 0
      }
    }
  }

  const changeOpenState = () => {
    setIsOpenNav(!isOpenNav);
  }

  const isProjectRoot = () => {
    const reg = new RegExp("/(.*?)(?=/)");
    const mathArr = location.pathname.match(reg);
    if(mathArr==null) return false;
    else if(mathArr[1] == 'project') return true;
    else return false;
  }

  const setHideClass = () => {
    if(typeof window === 'undefined') return;
    if(isProjectRoot()==true && !props.isHideHeader && headerDom.current!=null) {
      headerDom.current.classList.add(styles.headerIsHide);
      props.setHeader(true);
    }else if(isProjectRoot()==false&& !props.isHideHeader && headerDom.current!=null) {
      headerDom.current.classList.remove(styles.headerIsHide);
      props.setHeader(false);
    }
  }

  useEffect(() => {
    if(typeof window === 'undefined') return;
    const html = document.getElementsByTagName('html')[0];
    if(isOpenNav&&!html.classList.contains('is-fixed')) html.classList.add('is-fixed');
    else if(!isOpenNav&&html.classList.contains('is-fixed')) html.classList.remove('is-fixed');
  })

  useEffect(() => {
    setHideClass();
  });

  return(
    <header
      className={styles.header + ' ' + styles.headerIsHide}
      ref={headerDom}
    >
        <MenuButton
          isOpen={isOpenNav}
          setOpen={changeOpenState}
          layoutName={props.layoutName}
        />
        <div className={isOpenNav? styles.headerNav + ' ' + styles.isActive : styles.headerNav}>
          <div className={styles.headerNavWrapper}>
            <Navigation
              layoutName={props.layoutName}
              isOpen={isOpenNav}
              setOpen={changeOpenState}
              researchLink={props.researchLink}
            />
            <div className={styles.headerLogo}>
              <LogoItem
                layoutName={props.layoutName}
                isOpen={isOpenNav}
                isInHeader={true}
                setOpen={changeOpenState}
              />
            </div>
          </div>
        </div>
    </header>
  )
}

export default Header
