// custom CSS styles
import "./src/styles/main.sass"

import React from "react";
import Layout from "./src/layouts"

const transitionDelay = 300;
let isFirstAccess = true;
let ableLaunch = false;
let timeout = null;
// const location = useLocation();

export const wrapPageElement = ({ element, props }) => {
  if(typeof window === 'undefined') return;
  document.getElementsByTagName('html')[0].classList.remove('is-page-loaded');
  document.getElementsByTagName('html')[0].classList.remove('is-fontplus-loaded');
  return <>{element}</>;
};

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  if(typeof window === 'undefined') return;
  if(timeout != null) window.clearTimeout(timeout);
  if (location.action === "PUSH") {
    timeout = window.setTimeout(() => {
      window.scrollTo(0, 0);
      document.getElementsByTagName('html')[0].classList.add('is-page-loaded');
    }, transitionDelay);
  } else {
    const savedPosition = getSavedScrollPosition(location);
    timeout = window.setTimeout( () => {
      window.scrollTo(...(savedPosition || [0, 0]));
      document.getElementsByTagName('html')[0].classList.add('is-page-loaded');
    },
      transitionDelay
    );
  }
  return false;
};