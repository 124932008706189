import React, { useState, useRef, useEffect, useLayoutEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import * as styles from './style.module.sass'
import { useLocation } from "@reach/router"
//- motion ref https://www.framer.com/docs/transition/
import PrependsScripts from "../scripts/_prepends"
import Transition from "../components/organisms/transition"

import Header from "../components/organisms/Header"

const Layout = (props) => {
  PrependsScripts(useRef, useEffect); //- scriptsディレクトリ格納のscript群

  const [isHideHeader, changeHeaderState] = useState(null);

  const wrapDom = useRef(null);
  const location = useLocation();

  const detectInstagram = () => {
    //https://zenn.dev/kecy/articles/f51851e42c4243
    const ua = window.navigator.userAgent.toLowerCase().trim();
    const isIOS = ua.includes('iphone') || ua.includes('ipod') || ua.includes('ipad');
    if (ua.includes('instagram') && isIOS) {
      if(window.name != "reloaded") {
        location.reload();
        window.name="reloaded";
      }
    }
  }

  const setHtmlData = () => {
    const reg = new RegExp("/(.*?)(?=/)");
    const mathArr = props.location.pathname.match(reg);
    if(mathArr==null) return '';
    return mathArr[1];
  }

  const isProjectRoot = () => {
    if(wrapDom.current == null) return;
    else if(wrapDom.current.getAttribute('data-path')  == 'project') return true;
    else return false;
  }

  const activatePage = () => {
    document.getElementsByTagName('html')[0].classList.add('is-fontplus-loaded');
        const target = document.getElementsByClassName(styles.wrapper)[0];
        if(target!=null) target.classList.add(styles.wrapperIsActive);
        if(!isProjectRoot()) changeHeaderState(false);
        else changeHeaderState(true);
  }

  const loadFont = () => {
    // console.log('before loading');
    if(typeof FONTPLUS !== "undefined") {
      if(FONTPLUS.isloading()) return;
      // console.log('start');
      FONTPLUS.start();
      const fontLoaded = (_res) => {
        // console.log('loaded & res code is ' + _res.code);
        if(_res.code == 0) {
          // console.log('------------------------');
          activatePage();
        }
        else {
          // console.log('retry');
          loadFont();
        }
      }
      FONTPLUS.attachCompleteEvent(fontLoaded);
    }else{
      activatePage(); //- undefinedの時はnoto sansで表示
    }
    
    // setTimeout(() => {
    //   activatePage();
    // }, 500);
  }

  useEffect(() => {
    if(typeof window === 'undefined') return;
    detectInstagram();
    const target = document.getElementsByTagName('html')[0];
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if(mutation.target.classList.contains('is-page-loaded')&&!mutation.target.classList.contains('is-fontplus-loaded')) {
          loadFont();
        }
      });
    });
    const config = {attributes:true};
    observer.observe(target, config);
    document.getElementsByTagName('html')[0].classList.add('is-page-loaded');
    return () => {
      observer.disconnect();
    }
  }, []);

  const {
    wp: {
      generalSettings: { title },
    },
    option: data
  } = useStaticQuery(graphql`
    query {
      wp: wp {
        generalSettings {
          title
          description
        }
      }

      option: allWp {
        edges {
          node {
            themeOptions {
              commonResearch {
                researchLink
              }
            }
          }
        }
      }
    }
  `)


  return (
    <div
      ref={wrapDom}
      data-path={setHtmlData()}
    >
      <Header
        isHideHeader = {isHideHeader}
        layoutName = {setHtmlData()==''? '/' : setHtmlData()}
        setHeader = {(_state) => {changeHeaderState(_state)}}
        researchLink = {data.edges[0].node.themeOptions.commonResearch.researchLink}
      />
      <Transition
        location={props.location}
      >
        <div
          className={styles.wrapper}
        >
          {props.children}
        </div>
      </Transition>
    </div>
  )
}

export default Layout
