const setUpView = () => {
  const html = document.getElementsByTagName('html')[0];
  let vh = window.innerHeight * 0.01;
  let vw = html.clientWidth * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  document.documentElement.style.setProperty('--vw', `${vw}px`);
}

//- spの時に読み込んだ時のみリサイズして欲しいものを指定する
const setupSpVh = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--spvh', `${vh}px`);
}

export const resize = (_isMobile, _isResizeWidth) => {
  setUpView();
  if(!_isMobile || _isMobile && _isResizeWidth) setupSpVh();
}

export const init = () => {
  if(typeof window === 'undefined') return;
  setUpView();
  setupSpVh();
}