import React, {useLayoutEffect, useRef} from 'react'
import * as styles from './style.module.sass'

import HoverProvider from '../HoverProvider'

const MenuButton = (props) => {
  const isMobile = useRef(
    typeof window === 'undefined'? false :
    (/android|ipod|ipad|iphone|macintosh/.test(navigator.userAgent.toLowerCase()) && 'ontouchend' in document)? true : false
  ),
  containerDom = useRef(typeof window === 'undefined'? null : document.getElementById('container')),
  scrollTarget = useRef(typeof window === 'undefined'? null : props.layoutName=='/'&&containerDom.current!=undefined&&isMobile.current? containerDom.current : window),
  documentTarget = useRef(typeof window === 'undefined'? null : props.layoutName=='/'&&containerDom.current!=undefined&&isMobile.current? containerDom.current : document.documentElement),
  scrollPosBefore = useRef(0),
  ticking = useRef(false),
  target = useRef(null);

  const updateStatus = (_scrollTop) => {
    if(target.current==null) return;
    if((_scrollTop < scrollPosBefore.current || _scrollTop < 10)&&target.current.classList.contains(styles.menuButtonIsScrollHide)) {
      target.current.classList.remove(styles.menuButtonIsScrollHide);
    }else if(_scrollTop > scrollPosBefore.current && _scrollTop >= 10 && !target.current.classList.contains(styles.menuButtonIsScrollHide)){
      target.current.classList.add(styles.menuButtonIsScrollHide);
    }
  }

  const scrollEventTicking = () => {
    if( !ticking.current ) {
      window.requestAnimationFrame( () => {
        const scrollTop = scrollTarget.current.pageYOffset || documentTarget.current.scrollTop;
        updateStatus(scrollTop);
        scrollPosBefore.current = scrollTop;
        ticking.current = false;
      });
      ticking.current = true;
    }
  }

  useLayoutEffect(() => {
    if(typeof window === 'undefined'  || props.layoutName=='/projects/') return;
    containerDom.current = document.getElementById('container');
    scrollTarget.current = typeof window === 'undefined'? null : props.layoutName=='/'&&containerDom.current!=undefined&&isMobile.current? containerDom.current : window;
    documentTarget.current = typeof window === 'undefined'? null : props.layoutName=='/'&&containerDom.current!=undefined&&isMobile.current? containerDom.current : document.documentElement;
    if(scrollTarget.current == null) return;
    ticking.current = false;
    scrollPosBefore.current = scrollTarget.current.pageYOffset || documentTarget.current.scrollTop;
    scrollTarget.current.addEventListener(`scroll`, scrollEventTicking, { passive: true });
    return () => {
      ticking.current = false;
      scrollTarget.current.removeEventListener(`scroll`, scrollEventTicking, { passive: true })
    }
  }, [props.layoutName, scrollTarget.current])

  return(
    <HoverProvider
      hoverClassName={styles.isHover}
    >
      <button
        className={
          props.isOpen? styles.menuButton + " " + styles.isActive : styles.menuButton
        }
        onClick={props.setOpen}
        refTo={target}
      >
        <span />
        <span />
        <span />
      </button>
    </HoverProvider>
  )
}

export default MenuButton
