//- refs: https://blog.nabeliwo.com/2018/05/react-hover/

import React, {useState, useEffect, useRef} from "react"
import * as styles from './style.module.sass'

const HoverProvider = (props) => {
  const [isHover, setHover] = useState(false);
  const isMobile = useRef(
    typeof window === 'undefined'? false :
    (/android|ipod|ipad|iphone|macintosh/.test(navigator.userAgent.toLowerCase()) && 'ontouchend' in document)?true : false
  )
  // const linkDom = useRef(null);

  const addHover = () => {
    setHover(true);
  }

  const removeHover = () => {
    setHover(false);
  }

  if(props.children.props.to || props.children.props.href) {
    //- Linkの時
    return(
      <props.children.type
        className={`${props.children.props.className} ${isHover? props.hoverClassName : ''}`}
        to={props.children.props.to}
        href={props.children.props.href}
        target={props.children.props.target}
        rel={props.children.props.rel}
        state={props.children.props.state}
        ref={props.children.props.refTo}
        download={props.children.props.download}
        onClick={props.children.props.onClick}
        onMouseEnter={() => {if(!isMobile.current) addHover()}}
        onMouseLeave={() => {if(!isMobile.current) removeHover()}}
        onTouchStart={() => {if(isMobile.current) addHover()}}
        onTouchEnd={() => {if(isMobile.current) removeHover()}}
        onTouchCancel={() => {if(isMobile.current) removeHover()}}
      >
        {props.children.props.children}
      </props.children.type>
    )
  }else{
    //- Buttonの時
    return(
      <props.children.type
        className={`${props.children.props.className} ${isHover? props.hoverClassName : ''}`}
        ref={props.children.props.refTo}
        onClick={props.children.props.onClick}
        onMouseEnter={() => {if(!isMobile.current) addHover()}}
        onMouseLeave={() => {if(!isMobile.current) removeHover()}}
        onTouchStart={() => {if(isMobile.current) addHover()}}
        onTouchEnd={() => {if(isMobile.current) removeHover()}}
        onTouchCancel={() => {if(isMobile.current) removeHover()}}
      >
        {props.children.props.children}
      </props.children.type>
    )
  }
}

export default HoverProvider
