module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://web-api.nomena.co.jp/graphql","schema":{"perPage":10,"timeout":300000,"requestConcurrency":1,"previewRequestConcurrency":1,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-YMPC3CE939"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"render-blocking","web":[{"name":"Noto Sans JP","file":"https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400&display=swap"},{"name":"Noto Sans","file":"https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"nomena web","short_name":"nomena web","start_url":"/","background_color":"#fff","theme_color":"#000","display":"minimal-ui","icon":"content/assets/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b7c228fdca3ae09e906950b167b45f47"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
