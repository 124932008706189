//- refs: https://blog.nabeliwo.com/2018/05/react-hover/

import React from "react"
import { Link } from "gatsby"
import LogoSvg from '../../../images/logo.svg'
import * as styles from './style.module.sass'

const LogoItem = (props) => {
  return(
    <Link
      to="/"
      className={styles.Logo}
      onClick={() => {if(props.isInHeader) props.setOpen();}}
    >
      <img className={styles.LogoImg} src={LogoSvg} />
    </Link>
  )
}

export default LogoItem
