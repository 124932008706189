import React from "react"
import * as styles from './style.module.sass'
import { useLocation } from "@reach/router"

import MenuItemLink from '../../atoms/MenuItemLink'

const Navigation = (props) =>{
  const navigationList = [
    {internal: true, link: "/", text: 'Top'},
    {internal: true, link: '/projects/', text: 'Projects'},
    {internal: true, link: '/about/', text: 'About'},
    {internal: false, link: props.researchLink, text: 'Research'},
    {internal: true, link: '/news/', text: 'News'},
    {internal: false, link: 'https://gallery.nomena.co.jp/', text: 'Exhibition'},
    {internal: true, link: '/contact/', text: 'Contact'},
  ];

  const location = useLocation();

  return(
    <nav className={styles.navigation}>
      <ul className={styles.navigationList}>
        {navigationList.map((navigationItem, index) => {
          if(navigationItem.link != null) {
            return (
              <li
                key={index}
                className={styles.navigationItem}
              >
                <MenuItemLink
                  layoutName={props.layoutName}
                  isNowLocation={location.pathname==navigationItem.link? true : false}
                  linkTo={navigationItem.link}
                  text={navigationItem.text}
                  internal={navigationItem.internal}
                  setOpen={() => {props.setOpen()}}
                />
              </li>
            )
          }
        })}
      </ul>
    </nav>
  )
}

export default Navigation
