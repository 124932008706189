import React from 'react'
import { Link } from "gatsby"
import * as styles from './style.module.sass'

import HoverProvider from '../HoverProvider'

const MenuItemLink = (props) => {
  if(props.internal) {
    return(
      <HoverProvider hoverClassName={styles.isHover}>
        <Link
          to={props.linkTo}
          className={
            props.isNowLocation? styles.menuItemLink + ' ' + styles.isActive : styles.menuItemLink
          }
          onClick={() => {props.setOpen()}}
        >
          {props.text}
        </Link>
      </HoverProvider>
    )
  }else{
    return(
      <HoverProvider hoverClassName={styles.isHover}>
        <a
          className={styles.menuItemLink}
          href={props.linkTo}
          target="_blank"
          rel="noopener noreferrer"
        >
          {props.text}
        </a>
      </HoverProvider>
    )
  }
}

export default MenuItemLink
